import React from "react";

const CopyRight = () => {

    return (
        <section
            className="elementor-section elementor-element elementor-element-19514636 elementor-section-boxed"
            data-id="19514636" data-element_type="section"
            data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
            <div className="elementor-container elementor-column-gap-default">
                <div
                    className="elementor-column elementor-col-100 elementor-element elementor-element-2a085b9a"
                    data-id="2a085b9a" data-element_type="column">
                    <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                            className="elementor-element elementor-element-21172d53 elementor-widget elementor-widget-text-editor"
                            data-id="21172d53" data-element_type="widget"
                            data-widget_type="text-editor.default">
                            <div className="elementor-widget-container">
                                <p>© 2024 George Automotive. All rights reserved</p></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CopyRight;
