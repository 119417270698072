import '../styles/ContactUs.css';
import React from "react";
import {Call, Location, Mail} from "react-ionicons";

const ContactUs = () => {
    return (
        <section className="elementor-section elementor-element">
            <div className="elementor-container">
                <div className="elementor-column elementor-col-50 elementor-element">
                    <iframe
                        allowFullScreen=""
                        height="520"
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10056.866316619697!2d-0.1053452!3d50.9382076!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48758d865a1c1c95%3A0xeac87e14c835d771!2sGeorge%20Automotive!5e0!3m2!1sen!2suk!4v1703073746945!5m2!1sen!2suk"
                        style={{border: 0}}
                        title="Google Map Location"
                        width="600"
                    />
                </div>

                <div className="contact-details-column elementor-column elementor-col-50">
                    <div className="elementor-element elementor-widget">
                        <div className="elementor-widget-container">
                            <h1 className="elementor-heading-title">Contact Us</h1>
                        </div>
                    </div>
                    <div className="divider"></div>
                    <ul className="contact-details-list">
                        <li className="contact-details">
                            <Location
                                color="#DAA520"
                                height="30px"
                                width="30px"
                            />
                            <a href="https://www.google.com/maps/place/George+Automotive/@50.9383327,-0.1082956,17z/data=!4m14!1m7!3m6!1s0x48758d865a1c1c95:0xeac87e14c835d771!2sGeorge+Automotive!8m2!3d50.9382076!4d-0.1053452!16s%2Fg%2F11lchm6c4d!3m5!1s0x48758d865a1c1c95:0xeac87e14c835d771!8m2!3d50.9382076!4d-0.1053452!16s%2Fg%2F11lchm6c4d?entry=ttu" target="_blank">
                                Unit 1<br/>The Oaks Poultry Farm<br/>Common Lane<br/>Ditchling<br/>BN6 8TN
                            </a>
                        </li>
                        <li className="contact-details">
                            <Mail
                                color="#DAA520"
                                height="30px"
                                width="30px"
                            />
                            <a href="mailto:georgeautomotive@yahoo.com">georgeautomotive@yahoo.com</a>
                        </li>
                        <li className="contact-details">
                            <Call
                                color="#DAA520"
                                height="30px"
                                width="30px"
                            />
                            <a href="tel:07557737924">07557 737924</a>
                        </li>
                    </ul>
                </div>

            </div>
        </section>
    );
}

export default ContactUs;
