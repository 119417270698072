import '../styles/WhyChooseUs.css';
import React from "react";

import underBonnet from '../images/under-bonnet.jpg';

const WhyChooseUs = () => {
    return (
        <section
            className="elementor-section elementor-element elementor-element-43f97b2e elementor-section-content-middle elementor-section-boxed">
            <div className="elementor-background-overlay"></div>
            <div className="elementor-shape elementor-shape-bottom" data-negative="false">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                    <path className="elementor-shape-fill" d="M0,6V0h1000v100L0,6z"/>
                </svg>
            </div>
            <div className="elementor-container elementor-column-gap-default">
                <div
                    className="elementor-column elementor-col-50 elementor-element elementor-element-15c1ac59"
                    data-id="15c1ac59" data-element_type="column">
                    <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                            className="elementor-element elementor-element-6266aef7 elementor-widget elementor-widget-image"
                            data-id="6266aef7" data-element_type="widget" data-widget_type="image.default">
                            <div className="elementor-widget-container">
                                <img
                                    loading="lazy"
                                    decoding="async"
                                    width="800"
                                    height="534"
                                    src={underBonnet}
                                    className="attachment-large size-large wp-image-26"
                                    alt="Engine bay"
                                    sizes="(max-width: 800px) 100vw, 800px"
                                />
                            </div>
                        </div>
                        <div
                            className="elementor-element elementor-element-632f2b97 elementor-blockquote--skin-boxed elementor-blockquote--align-center elementor-widget__width-initial elementor-absolute elementor-widget-mobile__width-initial elementor-blockquote--button-color-official elementor-widget elementor-widget-blockquote"
                            data-id="632f2b97" data-element_type="widget"
                            data-settings="{&quot;_position&quot;:&quot;absolute&quot;}"
                            data-widget_type="blockquote.default">
                            <div className="elementor-widget-container">
                                <blockquote className="elementor-blockquote">
                                    <p className="elementor-blockquote__content">Years Experience</p>
                                    <div className="e-q-footer">
                                        <cite className="elementor-blockquote__author">10+</cite>
                                    </div>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="elementor-column elementor-col-50 elementor-element elementor-element-52983086"
                    data-id="52983086" data-element_type="column">
                    <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                            className="elementor-element elementor-element-4bac0a2e elementor-widget"
                            data-id="4bac0a2e" data-element_type="widget"
                            data-widget_type="heading.default">
                            <div className="elementor-widget-container">
                                <h2 className="elementor-heading-title">Why Choose Us</h2>
                            </div>
                        </div>
                        <div className="divider"></div>
                        <div
                            className="elementor-element elementor-element-2e56cf45 elementor-widget elementor-widget-text-editor"
                            data-id="2e56cf45" data-element_type="widget"
                            data-widget_type="text-editor.default">
                            <div className="elementor-widget-container">
                                <p>Your car is important. It gets you to and from work, and supports your busy
                                    lifestyle. We will take care of it in the same way you treasure it.</p></div>
                        </div>
                        <div
                            className="elementor-element elementor-element-1d1941ec elementor-widget__width-initial ekit-equal-height-disable elementor-widget elementor-widget-elementskit-icon-box"
                            data-id="1d1941ec" data-element_type="widget"
                            data-widget_type="elementskit-icon-box.default">
                            <div className="elementor-widget-container">
                                <div className="ekit-wid-con">

                                    <div
                                        className="elementskit-infobox text-center text- icon-top-align elementor-animation">
                                        <div className="box-body">
                                            <h3 className="elementskit-info-box-title">
                                                Expert Mechanics
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="elementor-element elementor-element-27a31259 elementor-widget__width-initial ekit-equal-height-disable elementor-widget elementor-widget-elementskit-icon-box"
                            data-id="27a31259" data-element_type="widget"
                            data-widget_type="elementskit-icon-box.default">
                            <div className="elementor-widget-container">
                                <div className="ekit-wid-con">
                                    <div
                                        className="elementskit-infobox text-center text- icon-top-align">
                                        <div className="box-body">
                                            <h3 className="elementskit-info-box-title">
                                                Reasonably Priced
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="elementor-element elementor-element-3e497bff elementor-widget__width-initial ekit-equal-height-disable elementor-widget elementor-widget-elementskit-icon-box"
                            data-id="3e497bff" data-element_type="widget"
                            data-widget_type="elementskit-icon-box.default">
                            <div className="elementor-widget-container">
                                <div className="ekit-wid-con">
                                    <div
                                        className="elementskit-infobox text-center text- icon-top-align">
                                        <div className="box-body">
                                            <h3 className="elementskit-info-box-title">
                                                Fast Work &amp; Great Service
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="elementor-element elementor-element-12315986 elementor-widget__width-initial ekit-equal-height-disable elementor-widget elementor-widget-elementskit-icon-box"
                            data-id="12315986" data-element_type="widget"
                            data-widget_type="elementskit-icon-box.default">
                            <div className="elementor-widget-container">
                                <div className="ekit-wid-con">
                                    <div
                                        className="elementskit-infobox text-center text- icon-top-align">
                                        <div className="box-body">
                                            <h3 className="elementskit-info-box-title">
                                                A Trusted Provider
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default WhyChooseUs;
