import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-hook-consent/dist/styles/style.css';
import './styles/App.css';

import {ConsentBanner, ConsentProvider} from "react-hook-consent";
import {FloatingWhatsApp} from "react-floating-whatsapp";

import AboutUs from "./components/AboutUs";
import ContactUs from "./components/ContactUs";
import CopyRight from "./components/CopyRight";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import Navigation from "./components/Navigation";
import Sponsors from "./components/Sponsors";
import WhatClientsSay from "./components/WhatClientsSay";
import WhatWeOffer from "./components/WhatWeOffer";
import WhyChooseUs from "./components/WhyChooseUs";

import logo from './images/logo-small.png';

const App = () => (
    <ConsentProvider
        options={{
            services: [],
            theme: 'light',
        }}
    >
        <Navigation/>
        <div className="single elementor-kit-6 elementor-page">
            <div className="elementor elementor-17">
                <Hero/>
                <AboutUs/>
                <WhatWeOffer/>
                <WhyChooseUs/>
                <WhatClientsSay/>
                <Sponsors/>
            </div>
        </div>

        <div className="elementor elementor-14 elementor-location-footer">
            <ContactUs/>
            <Footer/>
            <CopyRight/>
        </div>

        <div>
            <FloatingWhatsApp
                accountName="George Automotive"
                allowClickAway={true}
                allowEsc={true}
                avatar={logo}
                chatMessage="Hello there! How can we help?"
                phoneNumber="+447557737924"
                statusMessage="We'll get back to you ASAP"
            />
        </div>

        <ConsentBanner
            settings={{hidden: true}}
            decline={{hidden: false, label: 'No'}}
            approve={{label: 'Yes'}}
        >
            To provide you with the best possible experience, we use cookies and similar technologies. Some cookies are
            necessary to make our website work and cannot be refused. You can accept or decline the use of additional
            cookies, which we use only to improve your experience.
        </ConsentBanner>
    </ConsentProvider>
);

export default App;
