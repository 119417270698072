import './styles/old-styles/styles1.css';
import './styles/old-styles/styles2.css';
import './styles/old-styles/styles5.css';
import './styles/old-styles/styles6.css';
import './styles/old-styles/styles7.css';
import './styles/old-styles/styles8.css';
import './styles/old-styles/styles9.css';
import './styles/old-styles/styles10.css';
import './styles/old-styles/styles11.css';
import './styles/old-styles/styles12.css';
import './styles/old-styles/styles13.css';
import './styles/custom-styles.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
